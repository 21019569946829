#paradise-header .banner-canvas {
  letter-spacing: 1.2px;
  width: 100%;
}

#paradise-header .label-container {
  text-align: right;
  margin: 0 0 -10px 0;
}

#paradise-header .ui.label.phone-label {
  margin: 0;
  background: none;
  border: none;
  font-size: 1.125rem;
}

#paradise-header .ui.label.phone-label .label-name {
  color: #ee6622;
  font-style: italic;
}

#paradise-header .ui.label.phone-label .detail {
  font-style: italic;
}

#paradise-header {
  margin-bottom: calc(2rem - 0.142857em);
}

#paradise-header .canvas-container {
  position: relative;
  margin: 0;
}

#paradise-header .canvas-container .play-pause-button {
  position: absolute;
  right: 1%;
  bottom: 30%;
  box-shadow: 0 0 0 2px #ffffff !important;
  border-radius: 30%;
  color: #ffffff !important;
}

#paradise-header .canvas-container .play-pause-button:hover {
  color: #ff4e19 !important;
  box-shadow: 0 0 1px 2px #ff4e19 !important;
}
