#paradise-contact .field:not(:first-child) {
  margin-top: 10px;
}

#paradise-contact .field .label {
  display: inline-block;
  vertical-align: top;
  width: 20%;
}

#paradise-contact .field .detail {
  display: inline-block;
  text-align: right;
  width: 80%;
}

#paradise-contact-error,
#paradise-contact-error > .header,
#paradise-contact-error > .header > .icon {
  color: #912d2b;
}
